import "./text-with-symbol.scss";
import React from "react";

const TextWithSymbol = ({
	title,
	desc,
	addres,
	hours,
	phone,
	arrive,
	localization,
	info,
}) => {
	return (
		<div className="text-with-symbol">
			{title && (
				<div
					className="text-with-symbol__title"
					dangerouslySetInnerHTML={{ __html: title }}
				/>
			)}
			{desc && (
				<div
					className="text-with-symbol__desc"
					dangerouslySetInnerHTML={{ __html: desc }}
				/>
			)}
			<div className="text-with-symbol__container-info">
				{addres && (
					<p>
						<strong>Adres:</strong> {addres}
					</p>
				)}
				{hours && (
					<p>
						<strong>Godziny otwarcia:</strong> {hours}
					</p>
				)}
				{info && (
					<p>
						<strong>Dodatkowe informacje:</strong> {info}
					</p>
				)}
				{localization && (
					<p>
						<strong>Lokalizacja:</strong> {localization}
					</p>
				)}
				{phone && (
					<p>
						<strong>Telefon: </strong>
						<a href="tel:{phone}">{phone}</a>{" "}
					</p>
				)}
				{arrive && (
					<p>
						<strong>Dojazd:</strong> {arrive}
					</p>
				)}
			</div>
		</div>
	);
};

export default TextWithSymbol;
