import "styles/pages/page-resort.scss";
import React from "react";
import Layout from "components/Layout";
import Seo from "components/Seo";
import SubpageHeader from "components/SubpageHeader";
import TextWithSymbol from "components/TextWithSymbol";
import Breadcrumbs from "../components/Breadcrumbs";

const ResortPage = () => {
  return (
    <Layout headerColor="#FFCE2E">
      <Seo title="Dla wielbicieli plaż" />
      <SubpageHeader image={require("assets/images/kurorty-bg.png").default} />
      <Breadcrumbs currentLocationLabel="Dla wielbicieli plaż" />
      <section className="resort-page">
        <div className="container">
          <h2 className="section-title">Dla wielbicieli plaż</h2>
          <div className="row">
            <div className="col-xl-6 offset-xl-1">
              <div className="resort-page__text">
                <p>
                  Tajlandia to niezwykle różnorodny kraj. Wszystkie elementy
                  rajskiego wypoczynku w Tajlandii łączą się ze sobą. Tutaj
                  będziesz cieszyć się białymi plażami połączonymi z błękitną
                  wodą morską, niesamowicie pyszną tajską kuchnią, zabawnymi
                  klubami nocnymi, luksusowymi hotelami i oczywiście spa w
                  Tajlandii, które należą do najlepszych na świecie.
                </p>
                <p>
                  Oszustwo tego kraju obejmuje wiele wysp o niesamowitym pięknie
                  z najlepszymi kurortami i plażami, dlatego bardzo ważne jest,
                  aby zdecydować, dokąd pojechać do Tajlandii.
                </p>
                <p>
                  Podpowiemy, które kurorty plażowe są najciekawsze i gdzie się
                  wybrać w Tajlandii.
                </p>
              </div>
            </div>
            <div className="col-xl-3 offset-xl-1">
              <div className="resort-page__list ">
                <p className="resort-page__list-text">
                  <strong>Lista Kurortów, kliknij i dowiedz się więcej:</strong>
                </p>
                <a href="#phuket">
                  <TextWithSymbol title="Phuket" />
                </a>
                <a href="#krabi">
                  <TextWithSymbol title="Krabi" />
                </a>
                <a href="#phi-phi">
                  <TextWithSymbol title="Ko Phi Phi" />
                </a>
                <a href="#samui">
                  <TextWithSymbol title="Samui" />
                </a>
                <a href="#pattaya">
                  <TextWithSymbol title="Pattaya" />
                </a>
                <a href="#ko-chang">
                  <TextWithSymbol title="Ko Chang" />
                </a>
                <a href="#hua-hin">
                  <TextWithSymbol title="Hua Hin" />
                </a>
                <a href="#khao-lak">
                  <TextWithSymbol title="Khao Lak" />
                </a>
                <a href="#koh-phangan">
                  <TextWithSymbol title="Koh Phangan" />
                </a>
                <a href="#samet">
                  <TextWithSymbol title="Samet" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="resort-page__phuket" id="phuket">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-6">
              <h2 className="section-title">Phuket</h2>
              <div className="resort-page__text resort-page__text--phuket">
                <p>
                  Wyspa Phuket to największy i najpopularniejszy kurort w
                  Tajlandii. W zachodniej części wyspy znajduje się najczęściej
                  odwiedzane miasto Patong, które słynie z pięknych plaż i jest
                  sercem nocnego życia na Phuket. Nie brakuje też miejsc na
                  zakupy.
                </p>
                <p>
                  Phuket przyciąga turystów swoim naturalnym pięknem i
                  czystością. Tutaj reszta uważana jest za wyższą klasę i
                  przypadnie do gustu miłośnikom ciszy i spokoju, ale ceny są tu
                  nieco wyższe w porównaniu do innych kurortów w Tajlandii.
                </p>
                <p>
                  Radzimy wybrać wakacje w Phuket w najkorzystniejszym okresie
                  od listopada do kwietnia, kiedy pogoda jest słoneczna, a woda
                  podgrzewana do +28˚C. „Pora deszczowa” zaczyna się od maja do
                  października. Przybywając w tym okresie, prawdopodobnie
                  wpadnie w okres krótkotrwałych deszczów tropikalnych.
                </p>
              </div>
            </div>
            <div className="col-xl-5 offset-xl-1 resort-page__col-images resort-page__col-images--phuket">
              <img
                src={require("assets/images/kurorty-img-1.png").default}
                alt=""
                className="img-fluid resort-page__img-1 resort-page__img-1--phuket"
              />
              <img
                src={require("assets/images/kurorty-img-2.png").default}
                alt=""
                className="img-fluid resort-page__img-2 resort-page__img-2--phuket"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="resort-page__krabi" id="krabi">
        <div className="container">
          <div className="row flex-xl-row flex-column-reverse align-items-center">
            <div className="col-xl-6 text-center">
              <img
                src={require("assets/images/kurorty-img-3.png").default}
                alt=""
                className="img-fluid"
              />
            </div>
            <div className="col-xl-5 offset-xl-1">
              <h2 className="section-title">Krabi</h2>
              <div className="resort-page__text resort-page__text--krabi">
                <p>
                  Krabi to prowincja składająca się ze 130 wysp. Można się tu
                  dostać łodzią z Phuket lub korzystając z krajowych linii
                  lotniczych z Bangkoku.
                </p>
                <p>
                  Mówiąc o głównej atrakcji Krabi, warto zwrócić uwagę na
                  pobliskie jaskinie Khao-Khanap-Nam. Aby dostać się do jaskiń,
                  wybierz się na spacer morski z molo Chao-Fa. Nie mniej godne
                  atrakcje Krabi - Park Narodowy Bokkhorani, jaskinie Tham Lod i
                  Tham Phi Hua To Tham, klasztor w „jaskini tygrysa” Wat Tham
                  Sua, wodospady Hua Said i Klong Haeng. W Krabi popularne są
                  morskie safari i wycieczki na wyspy, w tym na Samui i Phi Phi.
                </p>
                <p>
                  Popularne plaże Krabi to Ao Nang, Hat Rei Le, Hat Nopharat
                  Thara i Hat Tham Phra Nang. Temperatura wody w morzu jest
                  bardzo ciepła, około +29˚C.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="resort-page__phi-phi" id="phi-phi">
        <div className="container">
          <div className="row">
            <div className="col-xl-6">
              <h2 className="section-title">Ko Phi Phi</h2>
              <div className="resort-page__text resort-page__text--phi-phi">
                <p>
                  Phi Phi to archipelag składający się z sześciu małych wysp, z
                  których największą jest Phi Phi Don. Wyspa Phi Phi inspiruje
                  rajskimi plażami, lagunami i drobnym piaskiem.
                </p>
                <p>
                  Nurkowanie na Phi Phi to przyjemność ze względu na
                  różnorodność podwodnego świata. Można tu spotkać nie tylko
                  mureny, płaszczki, barakudy, tuńczyki, makrele i żółwie
                  morskie, ale także bardziej niebezpieczne życie morskie:
                  tygrysa, czarnopłetwego, a nawet rekina wielorybiego.
                </p>
                <p>
                  Jeśli zastanawiasz się, gdzie zorganizować wesele w Tajlandii,
                  powinieneś zwrócić uwagę na wyspę Phi Phi, ponieważ wszyscy
                  nowożeńcy uwielbiają ją za samotność. Ta wyspa zyskała
                  popularność dzięki filmowaniu Leonarda DiCaprio w filmie
                  „Plaża”, który miał tu miejsce. Aby dostać się na Phi Phi,
                  musisz pokonać dystans 40 km od Phuket. Z czasem zajmie to nie
                  więcej niż 2 godziny. Lub z centrum Krabi z molo Chao-Fa
                  płynie prom, który zabierze Cię na Phi Phi w 2,5 godziny.
                </p>
              </div>
            </div>
            <div className="col-xl-5 offset-xl-1 text-center">
              <img
                src={require("assets/images/kurorty-img-4.png").default}
                alt=""
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="resort-page__samui" id="samui">
        <div className="container">
          <div className="row flex-xl-row flex-column-reverse">
            <div className="col-xl-7 resort-page__col-images resort-page__col-images--samui">
              <img
                src={require("assets/images/kurorty-img-5.png").default}
                alt=""
                className="img-fluid resort-page__img-1 resort-page__img-1--samui"
              />
              <img
                src={require("assets/images/kurorty-img-6.png").default}
                alt=""
                className="img-fluid resort-page__img-2 resort-page__img-2--samui"
              />
            </div>
            <div className="col-xl-5">
              <h2 className="section-title">Samui</h2>
              <div className="resort-page__text resort-page__text--samui">
                <p>
                  Wyspa Samui jest uwielbiana za wyjątkową przyrodę i mnóstwo
                  rozrywki, jest trzecią co do wielkości wyspą i prawdziwą perłą
                  Zatoki Syjamskiej. Rajskie plaże Samui są po prostu
                  przeznaczone do nurkowania i snorkelingu.
                </p>
                <p>
                  Przyjemnie jest jeździć po dżungli na wycieczki po Samui,
                  odwiedzać starożytne buddyjskie świątynie, skosztować tajskiej
                  kuchni w lokalnych restauracjach, bawić się w dyskotekach i
                  nocnych klubach. Warto zaznaczyć, że masaż w Tajlandii to
                  jeden z najlepszych sposobów na relaks, a tajskie masażystki
                  na Samui Ci w tym pomogą. Hotele w Tajlandii, w szczególności
                  na Samui, mają dość szeroką i zróżnicowaną politykę cenową:
                  tutaj znajdą zakwaterowanie i budżetowi turyści i VIP-y w
                  światowej klasy hotelach pierwszej klasy.
                </p>
                <p>
                  Samui Resort posiada piękne plaże położone we wschodniej
                  części wyspy. Chaweng to jedna z najpopularniejszych plaż na
                  wybrzeżu. Najlepsza plaża łączy malownicze zatoki z białym
                  piaskiem, różnorodne hotele na każdą kieszeń i rozrywkowe
                  życie nocne. Warto zwrócić uwagę na inne kurorty w Tajlandii,
                  położone na Samui: Lama, Bophut, Mayen, Gavkot Seth i Hat
                  Taling Ngam. Pogoda na wybrzeżu jest bardzo wygodna do
                  odpoczynku, temperatura wody wynosi około + 26-28˚C, a
                  temperatura powietrza w ciągu dnia wynosi około + 31-34˚C.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="resort-page__pattaya" id="pattaya">
        <div className="container">
          <div className="row">
            <div className="col-xl-6">
              <h2 className="section-title">Pattaya</h2>
              <div className="resort-page__text resort-page__text--pattaya">
                <p>
                  Najpopularniejszy kurort w Tajlandii - Pattaya. Rozrywka w
                  Pattaya przypadnie do gustu kochającym wolność młodym ludziom,
                  bo to po prostu raj imprez, na których można wyjechać w pełni.
                </p>
                <p>
                  Pattaya ma ogromny wybór całodobowych barów i restauracji,
                  klubów, w których można spędzić cały dzień.
                </p>
                <p>
                  Pattaya to duże zatłoczone miasto, więc nie licz na bardzo
                  czyste morze i plaże. Wszystkie plaże Pattaya są bardzo różne
                  i znajdują się z południa na północ: Jomtien, Nakloa i Palm
                  Beach, Dongtan. Centralna plaża miasta jest zatłoczona i dość
                  zatłoczona, a plaża Dongtan słynie z promenady. Plaża Jomtien
                  jest wybierana przez miłośników cichego i spokojnego
                  wypoczynku. A jeśli chcesz znaleźć najczystsze plaże, udaj się
                  do Nakloa i Palm Beach.
                </p>
                <p>
                  Na wakacje na słabo zaludnionej i czystej plaży najlepiej
                  wybrać się na wyspy Tajlandii, położone niedaleko Pattaya: Ko
                  Lan, Ko Saak, Ko Krok, Ko Phai. Pogoda w Tajlandii prawie
                  zawsze sprzyja wakacjom na plaży. Temperatura wody wynosi +
                  26-28˚C, a powietrze w ciągu roku stale nagrzewa się do +
                  30-34˚C.
                </p>
              </div>
            </div>
            <div className="col-xl-5 offset-xl-1 text-center">
              <img
                src={require("assets/images/kurorty-img-7.png").default}
                alt=""
                className="img-fluid resort-page__img-1 resort-page__img-1--pattaya"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="resort-page__ko-chang" id="ko-chang">
        <div className="container">
          <div className="row flex-xl-row flex-column-reverse">
            <div className="col-xl-6 text-center">
              <img
                src={require("assets/images/kurorty-img-8.png").default}
                alt=""
                className="img-fluid resort-page__img-1 resort-page__img-1--ko-chang"
              />
            </div>
            <div className="col-xl-5 offset-xl-1">
              <h2 className="section-title">Ko Chang</h2>
              <div className="resort-page__text resort-page__text--ko-chang">
                <p>
                  Wyspa Koh Chang jest drugą co do wielkości wyspą Tajlandii po
                  Phuket. Turyści z roku na rok coraz częściej odkrywają wyspę
                  Chang, przyjeżdżając tu w poszukiwaniu dziewiczej przyrody
                </p>
                <p>
                  I choć wyspa nie jest tak popularna jak jej brat Phuket, to i
                  tak jest dobra rozrywka: w lokalnych restauracjach można
                  docenić walory kuchni tajskiej, wiele barów i sklepów nie
                  znudzi. Duża część wyspy Koh Chang jest usiana nieprzejezdnymi
                  dżunglami, górami i wodospadami, co czyni ten obszar
                  szczególnie atrakcyjnym dla miłośników przyrody. Na Koh Chang
                  można nurkować, takie wrażenia na długo zostaną zapamiętane.
                  Do Ko Chang można dotrzeć promem z lądu prowincji Trat, do
                  którego z kolei można dotrzeć krajowymi liniami lotniczymi z
                  Bangkoku lub transportem lądowym, w szczególności z Bangkoku
                  lub Pattaya.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="resort-page__hua-hin" id="hua-hin">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-6">
              <h2 className="section-title">Hua Hin</h2>
              <div className="resort-page__text resort-page__text--hua-hin">
                <p>
                  Większość nazywa Hua Hin najbardziej prestiżowym i najstarszym
                  kurortem w Tajlandii.
                </p>
                <p>
                  Na mapie Tajlandii znajduje się nad brzegiem Zatoki
                  Syjamskiej, po przeciwnej stronie Pattaya. Dostaniesz się tu w
                  trzy godziny z Bangkoku. Lokalizacja światowej sławy hoteli
                  sprawiła, że wyspa ta jest atrakcyjna dla zamożnych ludzi i
                  VIP-ów. Rozrywka w Hua Hin jest również odpowiednia dla
                  urlopowiczów: na wyspie znajduje się wiele pól golfowych i
                  zadbanych parków. Wyspa Hua Hin ma jedne z najlepszych spa w
                  Tajlandii. Może to być zarówno tradycyjny masaż tajski, jak i
                  nowoczesne zabiegi. Najpopularniejsze zabiegi to głębokie
                  oczyszczanie skóry, masaże energetyczne, kursy detoksykacji
                  oraz medycyna alternatywna. Cechą i główną atrakcją Hua Hin
                  jest bliskie położenie Parku Narodowego Khao Sam Roy Yot.
                  Spacerując po parku zachwycą Cię obłędnie piękne wodospady,
                  jaskinie, doliny, namorzyny i zaciszne zatoki.
                </p>
              </div>
            </div>
            <div className="col-xl-5 offset-xl-1 resort-page__col-images resort-page__col-images--hua-hin">
              <img
                src={require("assets/images/kurorty-img-9.png").default}
                alt=""
                className="img-fluid resort-page__img-1 resort-page__img-1--hua-hin"
              />
              <img
                src={require("assets/images/kurorty-img-10.png").default}
                alt=""
                className="img-fluid resort-page__img-2 resort-page__img-2--hua-hin"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="resort-page__khao-lak" id="khao-lak">
        <div className="container">
          <div className="row flex-xl-row flex-column-reverse">
            <div className="col-xl-6 text-center">
              <img
                src={require("assets/images/kurorty-img-11.png").default}
                alt=""
                className="img-fluid resort-page__img-1 resort-page__img-1--khao-lak"
              />
            </div>
            <div className="col-xl-5 offset-xl-1">
              <h2 className="section-title">Khao Lak</h2>
              <div className="resort-page__text resort-page__text--khao-lak">
                <p>
                  Kao Lak to kraina lasów namorzynowych, niesamowicie pięknych
                  plaż i wzgórz porośniętych dżunglą.
                </p>
                <p>
                  Głównymi atrakcjami Khao Lak są trzy rezerwaty narodowe, które
                  są magnesem dla miłośników ekoturystyki. Najbardziej ulubionym
                  miejscem wszystkich nurków są wyspy Similan, które znajdują
                  się w pobliżu wybrzeża Khao Lak. Wszystko stworzone po to, by
                  spełnić marzenie: bogaty podwodny świat w połączeniu z
                  niesamowicie pięknymi rafami koralowymi tworzą idealne miejsce
                  do nurkowania i snorkelingu w Tajlandii. Na niezabudowanych
                  wyspach można cieszyć się pięknymi, zacisznymi wakacjami na
                  plaży i spacerami po malowniczych wzgórzach. Atrakcją, którą
                  trzeba zobaczyć w Khao Lak w południowej Tajlandii, jest
                  Morski Park Narodowy Phang Nga. Zapewni Ci niezapomniane
                  emocje, które pozostaną z Tobą na zawsze.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="resort-page__koh-phangan" id="koh-phangan">
        <div className="container">
          <div className="row">
            <div className="col-xl-6">
              <h2 className="section-title">Koh Phangan</h2>
              <div className="resort-page__text resort-page__text--koh-phangan">
                <p>
                  Młodzi ludzie lubią przyjeżdżać na wyspę Phangan na wakacje.
                  Ta wyspa jest jedną z pięciu największych wysp w Tajlandii i
                  jest popularna ze względu na imprezy na plaży.
                </p>
                <p>
                  Największy w Tajlandii show o nazwie Full Moon Party gromadzi
                  tysiące ludzi i odbywa się na tej wyspie co miesiąc. Z tej
                  okazji turyści czasami nazywają wyspę Koh Phangan - Pacific
                  Ibiza.
                </p>
                <p>
                  Popularne zajęcia, takie jak nurkowanie i snorkeling w
                  Phangan, gdzie doświadczeni instruktorzy pomogą Ci zdobyć
                  pierwsze doświadczenie w nurkowaniu. W Phangan można również
                  uprawiać inne atrakcje: przejażdżki na słoniach, spływy
                  kajakowe, safari, minigolf, narty wodne i wiele innych. Klimat
                  Phangan przypomina pogodę na wyspie Koh Samui. Najgorętsze
                  miesiące to kwiecień, maj, czerwiec. A najlepsza temperatura -
                  w okresie od listopada do marca i waha się od 24-26˚C w nocy
                  do 28-32˚C w dzień.
                </p>
              </div>
            </div>
            <div className="col-xl-5 offset-xl-1 text-center">
              <img
                src={require("assets/images/kurorty-img-12.png").default}
                alt=""
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="resort-page__samet" id="samet">
        <div className="container">
          <div className="row flex-xl-row flex-column-reverse ">
            <div className="col-xl-7 samet__col-images">
              <img
                src={require("assets/images/koh-samet.jpg").default}
                alt=""
                className="img-fluid resort-page__img-1 resort-page__img-1--samet"
              />
              <img
                src={require("assets/images/kurorty-img-14.png").default}
                alt=""
                className="img-fluid resort-page__img-2 resort-page__img-2--samet"
              />
            </div>
            <div className="col-xl-5">
              <h2 className="section-title">Rayong (Ko Samet)</h2>
              <div className="resort-page__text resort-page__text--samet">
                <p>
                  Samet to piękna wyspa w Tajlandii, słynąca z pięknych plaż,
                  raf koralowych i urzekających krajobrazów. Nic dziwnego, że
                  jest częścią Morskiego Rezerwatu Narodowego.
                </p>
                <p>
                  Główne atrakcje Samet - park narodowy w południowej części
                  miasta i wioska rybacka z klasztorem i świątynią na północy.
                  Plaże Samet można podzielić na dwie grupy: po zachodniej
                  stronie wyspy znajduje się piaszczysta plaża otoczona skałami,
                  a po wschodniej znajdują się długie plaże z drobnym piaskiem.
                  Na wyspę najlepiej dostać się z małego miasteczka Ban Phe.
                  Każdego ranka rano łodzie płyną w kierunku Samet i pokonują 85
                  km. Plaże wyspy są bardzo czyste i spodobają się miłośnikom
                  spokojnego wypoczynku i samotności. A piękne zatoki i czyste
                  morze nie ustępują popularnym plażom Phuket i Samui.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ResortPage;
